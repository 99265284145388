import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

import Header from '../layout/Header';
import Breadcrumb from '../layout/Breadcrumb';
import ApiClient from '../api/ApiClient';

const styles = theme => ({
  main: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  title: {
    padding: theme.spacing(2, 0, 0, 2)
  }
});

class Dashboard extends React.Component {
  render() {
    const { classes, history } = this.props;
    return (
      <div>
        <Header />
        <Breadcrumb>
          <Typography color="textPrimary">Devices</Typography>
        </Breadcrumb>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <MaterialTable
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Edit',
                  onClick: (event, rowData) => {
                    history.push(`/device/offline/${rowData.device_id}`);
                  }
                }
              ]}
              columns={[
                { title: 'Serial number', field: 'serial_number' },
                { title: 'Model', field: 'model' },
                { title: 'Init date', field: 'init_date' }
              ]}
              options={{
                pageSize: 10
              }}
              data={query =>
                new Promise((resolve, reject) => {
                  let url = `devices?page=${query.page + 1}&page_size=${
                    query.pageSize
                  }`;
                  if (query.search && query.search !== '')
                    url += `&search=${query.search}`;
                  if (query.orderBy) {
                    let order = query.orderDirection === 'asc' ? '' : '-';
                    order += query.orderBy.field;
                    url += `&ordering=${order}`;
                  }
                  ApiClient.fetchJson(url, 'GET', {}, response => {
                    let data = [];
                    response.results.forEach(element => {
                      element.init_date = element.init
                        ? moment(element.init_date).format('DD.MM.YYYY HH:mm')
                        : '';
                      data.push(element);
                    });

                    resolve({
                      data,
                      page: response.page - 1,
                      totalCount: response.count
                    });
                  });
                })
              }
              title="All devices"
            />
          </Paper>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isTokenRefreshing: state.auth.isTokenRefreshing,
  devices: state.devices.devices
});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(Dashboard))
);
