import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import NotyHelper from '../api/NotyHelper';
import Header from '../layout/Header';
import {
  fetchProfile,
  updateProfileRequest,
  updatePassword
} from '../reducers/profile';
import Breadcrumb from '../layout/Breadcrumb';

const styles = theme => ({
  main: {
    flexGrow: 1,
    padding: theme.spacing(0, 2, 2, 2)
  },
  form: {
    width: '100%'
  },
  textField: {
    flexFlow: 'wrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(1)
  }
});

class Profile extends React.Component {
  state = {
    showold_password: false,
    shownew_password: false,
    showConfirmPassword: false
  };

  componentDidMount() {
    const { dispatch } = this.props;
    fetchProfile(dispatch);
  }

  handleClickshowoldPassword = () => {
    this.setState(state => ({ showold_password: !state.showold_password }));
  };
  handleClickshownewPassword = () => {
    this.setState(state => ({ shownew_password: !state.shownew_password }));
  };
  handleClickshowConfirmPassword = () => {
    this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
  };

  render() {
    const { classes, profile, dispatch } = this.props;
    return (
      <div>
        <Header />
        <Breadcrumb>
          <Typography color="textPrimary">Profile</Typography>
        </Breadcrumb>
        <main className={classes.main}>
          {profile ? (
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h5" component="h3">
                  Profile
                </Typography>
                <Formik
                  initialValues={{
                    username: profile.username,
                    first_name: profile.first_name,
                    last_name: profile.last_name
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    updateProfileRequest(
                      dispatch,
                      values,
                      () => {
                        NotyHelper.success('Profile updated');
                        setSubmitting(false);
                      },
                      () => {
                        NotyHelper.error('Some error happened');
                        setSubmitting(false);
                      }
                    );
                    // setSubmitting(false);
                  }}
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required('Username is required.'),
                    first_name: Yup.string().required(
                      'First name is required.'
                    ),
                    last_name: Yup.string().required('Last name is required.')
                  })}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form
                      className={classes.form}
                      onSubmit={handleSubmit}
                      autoComplete="off"
                    >
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        error={touched.username && Boolean(errors.username)}
                        helperText={touched.username && errors.username}
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="First name"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Last name"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                      <Button
                        className={classes.submit}
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </form>
                  )}
                </Formik>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h5" component="h3">
                  Change password
                </Typography>
                <Formik
                  initialValues={{
                    old_password: '',
                    new_password: '',
                    confirmpassword: ''
                  }}
                  onSubmit={async (values, { setSubmitting, setErrors }) => {
                    const result = await updatePassword(
                      dispatch,
                      values
                      // () => {
                      //   setSubmitting(false);
                      // },
                      // () => {
                      //   NotyHelper.error('Some error happened');
                      //   setSubmitting(false);
                      // }
                    );

                    // this can't be written like if (result.success)
                    if (result.success === false) {
                      NotyHelper.error('Some error occured');
                    } else if (_.isEmpty(result)) {
                      NotyHelper.success('Password successfully updated');
                    } else {
                      setErrors(result);
                    }
                    setSubmitting(false);
                  }}
                  validationSchema={Yup.object().shape({
                    old_password: Yup.string().required(
                      'Current password is required.'
                    ),
                    new_password: Yup.string().required(
                      'New password is required.'
                    ),
                    confirmpassword: Yup.string().required(
                      'Confirm new password is required.'
                    )
                  })}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        type={this.state.showold_password ? 'text' : 'password'}
                        label="Current password"
                        name="old_password"
                        value={values.old_password}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        error={
                          touched.old_password && Boolean(errors.old_password)
                        }
                        helperText={touched.old_password && errors.old_password}
                        autoComplete="new-password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickshowoldPassword}
                              >
                                {this.state.showold_password ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        type={this.state.shownew_password ? 'text' : 'password'}
                        label="New password"
                        name="new_password"
                        value={values.new_password}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        error={
                          touched.new_password && Boolean(errors.new_password)
                        }
                        helperText={touched.new_password && errors.new_password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickshownewPassword}
                              >
                                {this.state.shownew_password ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        variant="outlined"
                        type={
                          this.state.showConfirmPassword ? 'text' : 'password'
                        }
                        label="Confirm new password"
                        name="confirmpassword"
                        value={values.confirmpassword}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        error={
                          touched.confirmpassword &&
                          Boolean(errors.confirmpassword)
                        }
                        helperText={
                          touched.confirmpassword && errors.confirmpassword
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickshowConfirmPassword}
                              >
                                {this.state.showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <Button
                        className={classes.submit}
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          ) : null}
        </main>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile.profile
});

export default connect(mapStateToProps)(withStyles(styles)(Profile));
