import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor } from './store';
import Login from './page/auth/login';
import Logout from './page/auth/logout';
import Dashboard from './page/dashboard';
import Profile from './page/profile';
import Device from './page/device';
import OfflineDevice from './page/offlineDevice';
import AllDevices from './page/allDevices';

function App({ store, history }) {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <div style={{ height: '100%' }}>
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />

              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/device/:id" component={Device} />
              <Route
                exact
                path="/device/offline/:id"
                component={OfflineDevice}
              />
              <Route exact path="/devices" component={AllDevices} />
            </div>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
