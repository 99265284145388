import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Terminal from '../Terminal';
import Paperhead from '../Paperhead';
import { setIsDeviceInitialized } from '../../reducers/devices';

const TerminalGrid = ({
  isDeviceInitialized,
  deviceId,
  token,
  classes,
  dispatch,
  terminalSocket
}) => {
  const [isInitDialogOpen, setIsInitDialogOpen] = useState(false);

  const onInit = () => {
    terminalSocket.send(`{"action": "run_init_script", "params": {}}`);
    setIsInitDialogOpen(false);
  };

  return (
    <>
      <Grid item>
        <Paper className={classes.terminal}>
          <Paperhead title={deviceId} />
          <div style={{ background: '#1c1c1c', paddingLeft: 7 }}>
            <Terminal deviceId={deviceId} token={token} />
          </div>
        </Paper>
        <Box my={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => setIsInitDialogOpen(true)}
          >
            Initialize
          </Button>
        </Box>
      </Grid>
      <Dialog
        open={isDeviceInitialized}
        onClose={() => dispatch(setIsDeviceInitialized(false))}
      >
        <DialogTitle>Device successfully initialized</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => dispatch(setIsDeviceInitialized(false))}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isInitDialogOpen}
        onClose={() => setIsInitDialogOpen(false)}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <br />
            Do you want to initialize?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsInitDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onInit} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TerminalGrid;
