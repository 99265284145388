import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Header from '../layout/Header';

// import WebSocketClient from '../api/WebSocketClient';

import { updateDevicesRequest } from '../reducers/devices';

const styles = theme => ({
  main: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  title: {
    padding: theme.spacing(2, 0, 0, 2)
  }
});

class Dashboard extends React.Component {
  componentDidMount() {
    updateDevicesRequest();
  }

  componentDidUpdate() {
    const { devices } = this.props;

    if (devices === null) {
      setTimeout(updateDevicesRequest, 1000);
    }
  }

  render() {
    const { classes, devices } = this.props;
    return (
      <div>
        <Header />
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              Online devices
            </Typography>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Serial number</TableCell>
                  <TableCell>Model</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices !== null &&
                  devices.map(device => (
                    <TableRow key={device.device_id}>
                      <TableCell component="th" scope="row">
                        <Link
                          component={RouterLink}
                          to={`/device/${device.device_id}`}
                        >
                          {device.serial_number}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {device.model}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isTokenRefreshing: state.auth.isTokenRefreshing,
  devices: state.devices.devices
});

export default connect(mapStateToProps)(withStyles(styles)(Dashboard));
