import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import MaterialTable, { MTableBodyRow } from 'material-table';

import CssList from './CssList';
import { updateCurrentDeviceRequest } from '../../reducers/devices';
import NotyHelper from '../../api/NotyHelper';
import { Link } from '@material-ui/core';

const API_URL = process.env.REACT_APP_API_URL;

const PrintDialog = ({
  isPrintDialogOpen,
  deviceId,
  setIsPrintDialogOpen,
  classes,
  printTemplates
}) => (
  <Dialog open={isPrintDialogOpen} onClose={() => setIsPrintDialogOpen(false)}>
    <Formik
      initialValues={{
        printTemplate: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        window.open(
          `${API_URL}devices/print/${deviceId}/${values.printTemplate}/`
        );
        setIsPrintDialogOpen(false);
      }}
      validationSchema={Yup.object().shape({
        printTemplate: Yup.string().required('Print template is required')
      })}
    >
      {({ values, errors, touched, handleChange, handleSubmit }) => (
        <>
          <DialogContent>
            <form onSubmit={handleSubmit} autoComplete="off">
              <TextField
                id="select-print-template"
                select
                label="Print template"
                variant="outlined"
                className={classes.formControl}
                name="printTemplate"
                value={values.printTemplate}
                onChange={handleChange}
                error={touched.printTemplate && Boolean(errors.printTemplate)}
                helperText={touched.printTemplate && errors.printTemplate}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {printTemplates.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}{' '}
                    {option.description ? `(${option.description})` : ''}
                  </MenuItem>
                ))}
              </TextField>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsPrintDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </>
      )}
    </Formik>
  </Dialog>
);

const DeviceInfoGrid = ({
  device,
  deviceInfo,
  classes,
  colors,
  deviceId,
  dispatch,
  printTemplates,
  sku
}) => {
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [isSKUDialogOpen, setIsSKUDialogOpen] = useState(false);
  const [selectedSku, setSelectedSku] = useState(null);
  const [selectedSkuId, setSelectedSkuId] = useState(device.sku);

  useEffect(() => {
    setSelectedSku(
      sku.reduce((acc, curval) => {
        return curval.id === selectedSkuId ? curval : acc;
      }, null)
    );
  }, [selectedSkuId]);

  return (
    <Grid item className={classes.listInfo}>
      <React.Fragment>
        <CssList dense>
          <ListItem>
            <ListItemText
              primary="Initialized"
              secondary={
                device.init
                  ? `Yes, ${moment(device.init_date).format(
                      'DD.MM.YYYY HH:mm'
                    )}`
                  : 'No'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Serial number"
              secondary={device.serial_number}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Model" secondary={device.model} />
          </ListItem>
          {deviceInfo ? (
            <React.Fragment>
              <ListItem>
                <ListItemText
                  primary="Release"
                  secondary={deviceInfo.release}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Version"
                  secondary={deviceInfo.version}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Machine"
                  secondary={deviceInfo.machine}
                />
              </ListItem>
            </React.Fragment>
          ) : null}
        </CssList>
        <Formik
          initialValues={{
            color: device.color,
            comment: device.comment,
            ext_serial_number: device.ext_serial_number
          }}
          onSubmit={(values, { setSubmitting }) => {
            updateCurrentDeviceRequest(
              dispatch,
              deviceId,
              {
                sku: selectedSkuId,
                ...values
              },
              () => {
                setSubmitting(false);
                NotyHelper.success('Saved successfully');
              },
              () => {
                setSubmitting(false);
                NotyHelper.error('Server error');
              }
            );
          }}
          validationSchema={Yup.object().shape({
            color: Yup.string()
              .required('Color is required')
              .nullable(),
            ext_serial_number: Yup.string()
              .max(20, 'Length must be less than 20 characters')
              .nullable()
          })}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            validateField
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <label>SKU</label>
              <br />
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsSKUDialogOpen(true);
                }}
              >
                {selectedSku !== null ? selectedSku.sku : 'Select SKU'}
              </Link>
              {selectedSku !== null ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    setSelectedSkuId(null);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Clear
                </Button>
              ) : null}
              <br />
              <br />
              <TextField
                label="External serial number"
                variant="outlined"
                className={classes.formControl}
                name="ext_serial_number"
                value={values.ext_serial_number}
                length={3}
                error={
                  touched.ext_serial_number && Boolean(errors.ext_serial_number)
                }
                helperText={
                  touched.ext_serial_number && errors.ext_serial_number
                }
                onChange={handleChange}
              />
              <br />
              <TextField
                id="select-color"
                select
                label="Color"
                variant="outlined"
                className={classes.formControl}
                name="color"
                value={values.color}
                onChange={handleChange}
                error={touched.color && Boolean(errors.color)}
                helperText={touched.color && errors.color}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {colors.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.color}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                label="Comment (optional)"
                multiline
                rows={2}
                variant="outlined"
                className={classes.formControl}
                name="comment"
                value={values.comment}
                onChange={handleChange}
              />
              <br />

              <Box my={1}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>{' '}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (!device.color) {
                      NotyHelper.error(
                        'The device has no color yet. Please select and save color before printing.'
                      );
                      return;
                    }
                    if (!device.init) {
                      NotyHelper.error(
                        'The device has not initialized yet. Please initialize it before printing.'
                      );
                      return;
                    }
                    setIsPrintDialogOpen(true);
                  }}
                >
                  Print
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </React.Fragment>
      <PrintDialog
        isPrintDialogOpen={isPrintDialogOpen}
        deviceId={deviceId}
        setIsPrintDialogOpen={setIsPrintDialogOpen}
        classes={classes}
        printTemplates={printTemplates}
      />
      <SKUTableModal
        isOpen={isSKUDialogOpen}
        setIsOpen={setIsSKUDialogOpen}
        sku={sku}
        setSelectedSkuId={setSelectedSkuId}
      />
    </Grid>
  );
};

const SKUTableModal = ({ isOpen, setIsOpen, sku, setSelectedSkuId }) => {
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'md'}
      onClose={() => setIsOpen(false)}
    >
      <MaterialTable
        className
        // actions={[
        //   {
        //     icon: 'edit',
        //     tooltip: 'Edit',
        //     onClick: (event, rowData) => {
        //       // history.push(`/device/offline/${rowData.device_id}`);
        //     }
        //   }
        // ]}
        // components={{
        //   Row: props => <MTableBodyRow {...props} onClick={() => alert(1)} />
        // }}
        onRowClick={(event, rowData) => {
          setSelectedSkuId(rowData.id);
          setIsOpen(false);
        }}
        columns={[
          { title: 'SKU', field: 'sku' },
          { title: 'Barcode', field: 'bar_code' },
          { title: 'Comment', field: 'comment' }
        ]}
        options={{
          pageSize: 10
        }}
        data={sku}
        title="SKU"
      />
    </Dialog>
  );
};

export default DeviceInfoGrid;
