import ApiClient from '../api/ApiClient';

const UPDATE_PROFILE = 'UPDATE_PROFILE';

export default function reducer(
  state = {
    profile: null
  },
  action = {}
) {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.profile
      };
    default:
      return state;
  }
}

export function updateProfile(profile) {
  return {
    type: UPDATE_PROFILE,
    profile
  };
}

export function fetchProfile(dispatch) {
  ApiClient.fetchJson('users/profile', 'GET', {}, function(response) {
    dispatch(updateProfile(response));
  });
}

export function updateProfileRequest(dispatch, data, successCb, errorCb) {
  ApiClient.fetchJson(
    'users/profile/',
    'PATCH',
    data,
    function(response) {
      dispatch(updateProfile(response));
      successCb(response);
    },
    errorCb
  );
}

export function updatePassword(dispatch, data, successCb, errorCb) {
  return ApiClient.fetchJson(
    'users/change_password/',
    'POST',
    data,
    null,
    // function(response) {
    //   console.log(response);
    //   successCb(response);
    // },
    errorCb,
    null,
    false,
    true
  );
}
