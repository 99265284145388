/* eslint-disable func-names */
import React from 'react';
import { Terminal } from 'xterm';
import 'xterm/dist/xterm.css';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  setTerminal,
  setTerminalSocket,
  setIsDeviceInitialized,
  getDeviceRequest
} from '../reducers/devices';
import NotyHelper from '../api/NotyHelper';

const WS_URL = process.env.REACT_APP_WS_URL;

class TerminalComponent extends React.Component {
  componentDidMount() {
    // const { dispatch, socket, deviceId } = this.props;
    this.terminal = new Terminal({
      cursorBlink: true,
      disableStdin: false,
      theme: { background: '#1c1c1c' },
      fontSize: 14,
      rows: 30,
      cols: 100
    });
    const { terminal } = this;

    const { dispatch, deviceId, token } = this.props;
    const socket = new WebSocket(`${WS_URL}tunnel/web/terminal/${deviceId}/`);

    socket.onopen = e => {
      console.log('connection opened');
      socket.send(
        // `{"action": "authentication", "params": {"token": "${token}", "device_id": "${deviceId}"}}`
        `{"action": "authentication", "params": {"token": "${token}"}}`
      );
    };

    socket.onmessage = function(e) {
      console.log(e);

      if (e.data !== 'hello') {
        const data = JSON.parse(e.data);
        const messageType = data.msg_type;

        switch (messageType) {
          case 'run_command':
            const { code } = data.detail;
            let line = !code ? data.detail.stdout : data.detail.stderr;
            line = line.replace(/\n/g, '\r\n');
            terminal.write(line);
            break;
          case 'show_message':
            NotyHelper.error(data.params.message);
            break;
          case 'device_init_success':
            dispatch(setIsDeviceInitialized(true));
            getDeviceRequest(deviceId);
            break;
          default:
            break;
        }
      }
    };

    socket.onclose = e => {
      if (_.isNumber(e.code) && e.code.toString().startsWith('30')) {
        NotyHelper.error(`Websocket connection error occured: code ${e.code}`);
      }
      console.log('Terminal onclose event:');
      console.log(e);
    };

    terminal.open(document.getElementById('terminal'));

    terminal.on('data', function(data) {
      const actionJson = {
        action: 'run_command',
        params: {
          //   device_id: deviceId,
          command: data
        }
      };

      socket.send(JSON.stringify(actionJson));
    });

    // terminal.on('paste', function(data) {
    //   terminal.write(data);
    // });

    dispatch(setTerminal(this.terminal));
    dispatch(setTerminalSocket(socket));
  }

  render() {
    return <div id="terminal" ref={el => (this.el = el)} />;
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(TerminalComponent);
