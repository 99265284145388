import React from 'react';

const styles = {
  container: {
    height: 20,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(180deg, #d2d1d1 10%, #cacaca 90%)'
  },
  badgeContainer: {
    position: 'absolute',
    display: 'flex',
    left: 0
  },
  badge: {
    width: 12,
    height: 12,
    marginLeft: 7,
    display: 'block',
    borderRadius: 12,
    background: '#ff4b46'
  },
  title: {
    fontSize: 14
  }
};

class Paperhead extends React.Component {
  render() {
    const { title } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.badgeContainer}>
          <span style={{ ...styles.badge, ...{ background: '#ff4b46' } }} />
          <span style={{ ...styles.badge, ...{ background: '#ffb62a' } }} />
          <span style={{ ...styles.badge, ...{ background: '#25c73a' } }} />
        </div>
        <div style={styles.title}>{title}</div>
      </div>
    );
  }
}

export default Paperhead;
