import store from '../store';
import ApiClient from '../api/ApiClient';

const UPDATE_DEVICE_LIST = 'UPDATE_DEVICE_LIST';
const SET_TERMINAL = 'SET_TERMINAL';
const SET_TERMINAL_SOCKET = 'SET_TERMINAL_SOCKET';
const SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE';
const SET_CURRENT_DEVICE_INFO = 'SET_CURRENT_DEVICE_INFO';
const SET_IS_DEVICE_INITIALIZED = 'SET_IS_DEVICE_INITIALIZED';

export default function reducer(
  state = {
    devices: null,
    terminal: null,
    socket: null,
    currentDevice: null,
    currentDeviceInfo: null,
    isDeviceInitialized: false
  },
  action = {}
) {
  switch (action.type) {
    case UPDATE_DEVICE_LIST:
      return {
        ...state,
        devices: action.devices
      };
    case SET_TERMINAL:
      return {
        ...state,
        terminal: action.terminal
      };
    case SET_TERMINAL_SOCKET:
      return {
        ...state,
        socket: action.socket
      };
    case SET_CURRENT_DEVICE:
      return {
        ...state,
        currentDevice: action.device
      };
    case SET_CURRENT_DEVICE_INFO:
      return {
        ...state,
        currentDeviceInfo: action.info
      };
    case SET_IS_DEVICE_INITIALIZED:
      return {
        ...state,
        isDeviceInitialized: action.isDeviceInitialized
      };
    default:
      return state;
  }
}

export function updateDevices(devices) {
  return {
    type: UPDATE_DEVICE_LIST,
    devices
  };
}

export function setTerminal(terminal) {
  return {
    type: SET_TERMINAL,
    terminal
  };
}

export function setTerminalSocket(socket) {
  return {
    type: SET_TERMINAL_SOCKET,
    socket
  };
}

export function setCurrentDevice(device) {
  return {
    type: SET_CURRENT_DEVICE,
    device
  };
}

function setCurrentDeviceInfo(info) {
  return {
    type: SET_CURRENT_DEVICE_INFO,
    info
  };
}

export function setIsDeviceInitialized(isDeviceInitialized) {
  return {
    type: SET_IS_DEVICE_INITIALIZED,
    isDeviceInitialized
  };
}

export function updateCurrentDeviceRequest(
  dispatch,
  id,
  values,
  successCb,
  errorCb
) {
  ApiClient.fetchJson(
    `devices/${id}/`,
    'PATCH',
    values,
    function(response) {
      dispatch(setCurrentDevice(response));
      successCb();
    },
    () => {
      errorCb();
    }
  );
}

export function updateDevicesRequest() {
  ApiClient.fetchJson('devices/online/', 'GET', {}, function(response) {
    store.dispatch(updateDevices(response));
  });
}

export function getDeviceRequest(id) {
  ApiClient.fetchJson(`devices/${id}`, 'GET', {}, function(response) {
    store.dispatch(setCurrentDevice(response));
    try {
      store.dispatch(setCurrentDeviceInfo(JSON.parse(response.device_info)));
    } catch (e) {
      store.dispatch(setCurrentDeviceInfo(null));
    }
  });
}
