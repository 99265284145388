import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LinkBreadcrumb from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

import ApiClient from '../api/ApiClient';
import DeviceInfoGrid from '../components/device/DeviceInfoGrid';
import Header from '../layout/Header';
import Breadcrumb from '../layout/Breadcrumb';
import { getDeviceRequest } from '../reducers/devices';

const styles = theme => ({
  main: {
    flexGrow: 1,
    padding: theme.spacing(0, 2, 2, 2)
  },
  terminal: {
    width: 830,
    overflowX: 'auto'
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 320
  },
  listInfo: {
    minWidth: 350
  }
});

class OfflineDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: '',
      comment: '',
      colors: [],
      printTemplates: [],
      isPrintDialogOpen: false,
      sku: []
    };

    this.setState = this.setState.bind(this);
  }

  getLists = () => {
    const setState = this.setState;

    ApiClient.fetchJson(`devices/lists/`, 'GET', {}, function(response) {
      setState({
        sku: response.sku,
        colors: response.colors,
        printTemplates: response.print_templates
      });
    });
  };

  componentDidMount() {
    getDeviceRequest(this.props.match.params.id);
    this.getLists();
  }

  render() {
    const { classes, match, device, deviceInfo, dispatch } = this.props;

    const { colors, printTemplates, sku } = this.state;

    return (
      <React.Fragment>
        <Header />
        <Breadcrumb>
          <LinkBreadcrumb color="inherit" component={Link} to="/devices">
            Devices
          </LinkBreadcrumb>

          <Typography color="textPrimary">{match.params.id}</Typography>
        </Breadcrumb>
        {device ? (
          <>
            <main className={classes.main}>
              <Grid container spacing={2}>
                <DeviceInfoGrid
                  device={device}
                  deviceInfo={deviceInfo}
                  classes={classes}
                  colors={colors}
                  deviceId={match.params.id}
                  dispatch={dispatch}
                  printTemplates={printTemplates}
                  sku={sku}
                />
              </Grid>
            </main>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

OfflineDevice.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  socket: state.auth.socket,
  token: state.auth.accessToken,
  device: state.devices.currentDevice,
  deviceInfo: state.devices.currentDeviceInfo,
  isDeviceInitialized: state.devices.isDeviceInitialized
});

export default connect(mapStateToProps)(withStyles(styles)(OfflineDevice));
