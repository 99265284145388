import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import 'animate.css';
import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

import './index.css';
import App from './App';
import store, { history } from './store';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
  // palette: {
  //   primary: {
  //     main: '#2196f3'
  //   }
  // },
  typography: {
    useNextVariants: true
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App history={history} store={store} />
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
