import _ from 'lodash';
import { push } from 'connected-react-router';
import store from '../store';
import WebSocketClient from '../api/WebSocketClient';
import NotyHelper from '../api/NotyHelper';

const API_URL = process.env.REACT_APP_API_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

const TOKEN_REFRESHING = 'TOKEN_REFRESHING';
const UPDATE_TOKENS = 'UPDATE_TOKENS';
const UPDATE_SOCKET = 'UPDATE_SOCKET';
const UPDATE_LOGIN_ERROR = 'UPDATE_LOGIN_ERRIR';

export default function reducer(
  state = {
    accessToken: null,
    refreshToken: null,
    isTokenRefreshing: false,
    socket: null,
    expiresIn: 0,
    isLoginError: false
  },
  action = {}
) {
  switch (action.type) {
    case UPDATE_TOKENS:
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        expiresIn: action.expiresIn
      };
    case TOKEN_REFRESHING:
      return {
        ...state,
        isTokenRefreshing: action.tokenRefreshing
      };
    case UPDATE_SOCKET:
      return {
        ...state,
        socket: action.socket
      };
    case UPDATE_LOGIN_ERROR:
      return {
        ...state,
        isLoginError: action.value
      };
    default:
      return state;
  }
}

export function updateTokens(response) {
  return {
    type: UPDATE_TOKENS,
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
    expiresIn: Date.now() + response.expires_in
  };
}

export function tokenRefreshing(value) {
  return {
    type: TOKEN_REFRESHING,
    tokenRefreshing: value
  };
}

export function updateSocket(socket) {
  return {
    type: UPDATE_SOCKET,
    socket
  };
}

export function updateLoginError(value) {
  return {
    type: UPDATE_LOGIN_ERROR,
    value
  };
}

export function logout(dispatch) {
  dispatch(
    updateTokens({ access_token: null, refresh_token: null, expiresIn: 0 })
  );
  dispatch(push('/login/'));

  return {
    type: 'USER_LOGOUT'
  };
}

export function login(username, password, dispatch) {
  const body = new FormData();
  body.append('username', username);
  body.append('password', password);
  body.append('grant_type', 'password');
  body.append('client_id', CLIENT_ID);
  body.append('client_secret', CLIENT_SECRET);

  let successCb = async function(response) {
    if (response.status === 400) {
      // NotyHelper.error('Invalid username or password');
      dispatch(updateLoginError(true));
      return;
    }

    if (response.status !== 200) {
      NotyHelper.error('Unknown error');
      return;
    }

    let json = await response.json();
    // console.log(json);

    dispatch(updateTokens(json));
    dispatch(updateSocket(new WebSocketClient(json.access_token)));
    dispatch(push('/dashboard'));
  };

  fetch(`${API_URL}o/token/`, {
    method: 'POST',
    body
  })
    .then(successCb)
    .catch(e => {
      NotyHelper.error('No connection to server. Try again later.');
    });
}

export function refreshToken(successCb) {
  var storeAuth = store.getState()['auth'];
  if (
    storeAuth.isTokenRefreshing === true ||
    storeAuth.expiresIn > Date.now()
  ) {
    return;
  }

  const dispatch = store.dispatch;
  dispatch(tokenRefreshing(true));
  console.log('token started refreshing');

  var formData = new FormData();

  formData.append('grant_type', 'refresh_token');
  formData.append('client_id', CLIENT_ID);
  formData.append('client_secret', CLIENT_SECRET);

  var _refreshToken = storeAuth.refreshToken;
  if (_refreshToken === null || _refreshToken === undefined) {
    dispatch(tokenRefreshing(false));
    logout(dispatch);
    return;
  }
  formData.append('refresh_token', _refreshToken);

  return fetch(`${API_URL}o/token/`, {
    method: 'POST',
    body: formData
  })
    .then(response => {
      if (response.status === 401) {
        logout();
        console.log('token could not be updated, logging out');
        return null;
      }
      try {
        return response.json();
      } catch {
        return { parseError: true };
      }
    })
    .then(response => {
      dispatch(tokenRefreshing(false));
      if (response === null || response.parseError) {
        return;
      }

      if (response !== null) {
        console.log('token updated');
        dispatch(updateTokens(response));
        if (_.isFunction(successCb)) {
          successCb(response);
        }
      }
    });
}
