import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authReducer from './auth';
import devicesReducer from './devices';
import profileReducer from './profile';

function rootReducer(state, action) {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  // else if (action.type === 'NO_CHANGES'){

  // }

  return null;
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    devices: devicesReducer,
    profile: profileReducer,
    root: rootReducer
  });
