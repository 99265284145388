import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';

import { login } from '../../reducers/auth';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    background: 'linear-gradient(45deg, #56C3B0 30%, #008BB4 90%)'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  message: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.error.dark
  },
  messageContent: {
    display: 'flex',
    alignItems: 'center'
  },
  messageIcon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

class SignIn extends React.Component {
  state = {
    username: '',
    password: '',
    remember: false,
    showPassword: false,
    isError: false
  };

  // handleChange = prop => event => {
  //   this.setState({ [prop]: event.target.value });
  // };

  componentDidMount() {
    const remember = localStorage.getItem('remember') === 'true';
    const username = remember ? localStorage.getItem('username') : '';
    const password = remember ? localStorage.getItem('password') : '';
    this.setState({ username, password, remember });
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  setError(value) {
    this.setState(state => ({ isError: value }));
  }

  render() {
    const { classes, dispatch, isLoginError } = this.props;
    const { username, password, remember } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Monitoreal
          </Typography>
          <Formik
            enableReinitialize
            initialValues={{
              username: username,
              password: password,
              remember: remember
            }}
            onSubmit={(values, actions) => {
              localStorage.setItem('remember', values.remember);
              localStorage.setItem(
                'username',
                values.remember ? values.username : ''
              );
              localStorage.setItem(
                'password',
                values.remember ? values.password : ''
              );
              login(values.username, values.password, dispatch);
              actions.setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().required('Username is required.'),
              password: Yup.string().required('Password is required.')
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                className={classes.form}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <TextField
                  name="username"
                  fullWidth
                  className={classes.textField}
                  label="User"
                  type="text"
                  value={values.username}
                  // onChange={this.handleChange('username')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  error={touched.username && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                />
                <TextField
                  name="password"
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  label="Password"
                  value={values.password}
                  // onChange={this.handleChange('password')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="remember"
                      checked={values.remember}
                      value={values.remember}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Remember me"
                />
                {isLoginError ? (
                  <SnackbarContent
                    className={classes.message}
                    aria-describedby="message-error"
                    message={
                      <span
                        id="message-error"
                        className={classes.messageContent}
                      >
                        <ErrorIcon className={classes.messageIcon} />
                        Invalid username or password.
                      </span>
                    }
                  />
                ) : null}
                <Button
                  type="submit"
                  fullWidth
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Log in
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </Container>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isLoginError: state.auth.isLoginError
});

// export default withStyles(styles)(SignIn);
export default connect(mapStateToProps)(withStyles(styles)(SignIn));
