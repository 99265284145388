import _ from 'lodash';

import store from '../store';
import { refreshToken, logout } from '../reducers/auth';
import NotyHelper from './NotyHelper';

const API_URL = process.env.REACT_APP_API_URL;

export default class ApiWrapper {
  // url: string,
  // method: string,
  // data: dict,
  // successCb: function
  // errorCb: function
  // errorCbDict: dict(key: error number, value: function)
  // useFullURL: boolean
  static fetchJson(
    url,
    method,
    data,
    successCb,
    errorCb = null,
    errorCbDict = null,
    useFullURL = false,
    useAsync = false
  ) {
    var accessToken = store.getState()['auth'].accessToken;

    if (accessToken === null) {
      logout(store.dispatch);
    }
    // var dispatch = store.dispatch;
    var requestOptions = {
      method: method,
      headers: {
        Accept: 'application/json;version=2.0',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken
      }
    };

    if (method !== 'GET' && method !== 'HEAD') {
      requestOptions.body = JSON.stringify(data);
    } else {
      var paramsString = '';
      Object.keys(data).forEach(
        key => (paramsString += key + '=' + data[key] + '&')
      );
      paramsString = paramsString.slice(0, -1);

      if (paramsString !== '') {
        if (!url.includes('?')) {
          url += '?' + paramsString;
        } else {
          url += '&' + paramsString;
        }
      }
    }

    let fullURL = useFullURL ? url : API_URL + url;

    let firstPromise = fetch(fullURL, requestOptions)
      .then(response => {
        if (response.status === 403) {
          console.log('unauthorized, trying to refresh token');
          refreshToken({
            url,
            method,
            data,
            successCb,
            errorCb,
            errorCbDict,
            useFullURL
          });
          return { success: false };
        }

        if (response.status === 400) {
          if (_.isFunction(errorCb)) {
            errorCb(response);
          }
          return response.json();
        }

        if (errorCbDict === null || errorCbDict === undefined) {
          if (response.status !== 200) {
            // dispatch(errorAlert(response));
            if (_.isFunction(errorCb)) {
              errorCb(response);
            }
            return { success: false };
          }
        } else {
          var keys = _.keys(errorCbDict);
          if (keys.includes(response.status.toString())) {
            errorCbDict[response.status](response);
            return { success: false };
          } else {
            if (response.status !== 200) {
              //   dispatch(errorAlert(response));
              errorCb(response);
              return { success: false };
            }
          }
        }

        try {
          return response.json();
        } catch {
          // return new Promise(resolve => {
          //   resolve({ success: false });
          // });
          return { success: false };
        }
      })
      .catch(e => {
        NotyHelper.error('No connection to server. Try again later.');
      });

    if (useAsync) {
      return firstPromise;
    } else
      return firstPromise.then(response => {
        if (response && response.success !== false) {
          successCb(response);
        }
      });
  }
}
