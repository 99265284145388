import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import logger from 'redux-logger';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createRootReducer from './reducers';
import WebSocketClient from './api/WebSocketClient';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const WebSocketTransform = createTransform(
  null,
  (outState, key) => {
    return {
      ...outState,
      socket:
        outState.accessToken !== null
          ? new WebSocketClient(outState.accessToken)
          : null,
      isTokenRefreshing: false,
      isLoginError: false
    };
  },
  {
    whitelist: ['auth']
  }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  transforms: [WebSocketTransform]
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const store = createStore(
  persistedReducer,
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  composeEnhancer(applyMiddleware(routerMiddleware(history), thunk, logger))
);

export const persistor = persistStore(store);

export default store;
