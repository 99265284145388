import Noty from 'noty';

export default class NotyHelper {
  static error(text) {
    new Noty({
      text: `<strong>Error!</strong><br />${text}`,
      type: 'error',
      layout: 'bottomRight',
      timeout: 5000,

      animation: {
        open: 'animated fadeIn fast', // Animate.css class names
        close: 'animated fadeOut faster' // Animate.css class names
      }
    }).show();
  }

  static success(text) {
    new Noty({
      text,
      type: 'success',
      layout: 'bottomRight',
      timeout: 2000,

      animation: {
        open: 'animated fadeIn fast', // Animate.css class names
        close: 'animated fadeOut faster' // Animate.css class names
      }
    }).show();
  }
}
