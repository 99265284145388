import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../reducers/auth';

class Logout extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    logout(dispatch);
  }

  render() {
    return null;
  }
}

export default connect()(Logout);
