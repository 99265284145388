import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  root: {
    '& .MuiListItem-root': {
      padding: theme.spacing(0)
    },
    '& .MuiListItemText-primary': {
      fontWeight: 'bold'
    }
  }
}))(List);
