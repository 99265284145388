/* eslint-disable func-names */
import { updateDevicesRequest } from '../reducers/devices';
import store from '../store';
import { refreshToken } from '../reducers/auth';
import NotyHelper from './NotyHelper';

const WS_URL = process.env.REACT_APP_WS_URL;

export default class WebSocketClient {
  constructor(token) {
    this.connect = this.connect.bind(this);
    // console.log(`token is ${token}`);
    this.reconnectTimes = 0;
    this.socket = null;
    this.connect(token);
  }

  send(message) {
    this.socket.send(message);
  }

  connect(token) {
    this.token = token;
    const socket = new WebSocket(`${WS_URL}tunnel/web/`);
    this.socket = socket;

    socket.onopen = e => {
      socket.send(
        `{"action": "authentication", "params": {"token": "${this.token}"}}`
      );
    };

    socket.onmessage = function(e) {
      if (e.data !== 'hello') {
        const data = JSON.parse(e.data);
        const messageType = data.msg_type;

        switch (messageType) {
          case 'device_connected':
            updateDevicesRequest();
            break;
          case 'device_disconnected':
            updateDevicesRequest();
            break;
          case 'run_command':
            const { code } = data.detail;
            let line = !code ? data.detail.stdout : data.detail.stderr;
            const { terminal } = store.getState().devices;
            line = line.replace(/\n/g, '\r\n');
            // terminal.clearPrompt();
            terminal.write(line);
            // terminal.prompt();
            break;
          default:
            break;
        }
      }
    };

    let reconnect = function(code) {
      const { connect } = this;
      this.reconnectTimes += 1;

      if (code === 3001) {
        socket.close(1000);
        refreshToken(response => connect(response.access_token));
      } else {
        connect(token);
      }
      // console.log('wait finished');

      // if (this.reconnectTimes > 5) {
      //   return;
      // }
    };

    reconnect = reconnect.bind(this);

    socket.onclose = function(e) {
      // console.error('Socket closed unexpectedly');
      // console.error(e);
      // console.log('waiting before reconnect...');
      NotyHelper.error('Socket closed unexpectedly, trying to reconnect. ');
      this.reconnectTimes = 0;
      setTimeout(() => {
        reconnect(e.code);
      }, 5000);
    };
  }
}
