import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkBreadcrumb from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  paper: {
    padding: theme.spacing(2, 2)
  }
}));

function Breadcrumb(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs aria-label="Breadcrumb">
          <LinkBreadcrumb color="inherit" component={Link} to="/dashboard">
            Home
          </LinkBreadcrumb>
          {children}
        </Breadcrumbs>
      </Paper>
    </div>
  );
}

export default Breadcrumb;
